import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Box, Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import InputField from "../../components/InputField"
import { BsCurrencyDollar } from "react-icons/bs";
import { Switch } from "antd";
import BlueButton from '../../components/common/Buttons/BlueButton';
import WhiteButton from '../../components/common/Buttons/WhiteButton';



const CustomBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    border: '1px solid #ced4da',
    borderRadius: '8px',
    padding: '8px 8px 8px',
    minHeight: '40px',
    backgroundColor: '#fff',
    '&:focus-within': {
        borderColor: '#88939D',
    },
}));

const Label = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    top: '-14px',
    left: '12px',
    backgroundColor: '#fff',
    padding: '0 4px',
    fontSize: '14px',
    color: '#172B4D',
    fontWeight: '600',
    zIndex: 1,
}));

const NewCard = ({ addNewCard, onClose }) => {
    const [emails, setEmails] = useState([]);
    const [currentEmail, setCurrentEmail] = useState('');
    const [subscriptionData, setSubscriptionData] = useState({
        name: '',
        price: '',
        billingFrequency: 'Select Billing Frequency',
        freeTrial: false,
        trialDays: '',
        description: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSubscriptionData({ ...subscriptionData, [name]: value });
    };

    const handleClose = () => {
        setSubscriptionData({
            name: '',
            price: '',
            billingFrequency: 'Select Billing Frequency',
            freeTrial: false,
            trialDays: '',
            description: '',
        });
        onClose();
    };

    const handleSwitchChange = (checked) => {
        setSubscriptionData({ ...subscriptionData, freeTrial: checked });
    };

    const handleSave = () => {
        console.log('New subscription data:', subscriptionData);
        onClose();
    };



    const handleEmailChange = (e) => {
        setCurrentEmail(e.target.value);
    };

    const handleAddEmail = (e) => {
        if (e.key === 'Enter' && currentEmail) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (emailRegex.test(currentEmail)) {
                setEmails([...emails, currentEmail]);
                setCurrentEmail('');
            } else {
                alert('Please enter a valid email address');
            }
            e.preventDefault();
        }
    };

    const handleDeleteEmail = (emailToDelete) => {
        setEmails(emails.filter((email) => email !== emailToDelete));
    };

    return (
        <Dialog
            open={addNewCard}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    padding: "5px",
                    width: { xs: "90%", sm: "70%", md: "50%" },
                    margin: { xs: "10px", sm: "auto" },
                }
            }}
        >
            <DialogTitle
                sx={{
                    color: "#172B4D",
                    fontWeight: "800",
                    fontSize: "18px",
                    lineHeight: "27px",
                }}
            >
                Generate New Cards
            </DialogTitle>
            <DialogContent>
                <InputField
                    id="cardsNum"
                    label="Number of Cards"
                    value={subscriptionData?.trialDays}
                    placeholder='Please enter number of cards to print.'
                    onChange={(e) => {
                        const value = e.target.value;
                        if (!isNaN(value) && Number(value) > 0) {
                            handleChange(e);
                        }
                    }}
                    required
                    sx={{
                        marginBottom: "15px",
                        marginTop: "5px",
                        fontWeight: "500",
                        fontSize: { xs: "14px", sm: "16px" },
                        lineHeight: "25px",
                        width: { xs: "100%", sm: "100%" },
                    }}
                />
                <Typography sx={{
                    color: "#172B4D",
                    fontWeight: "800",
                    fontSize: "16px",
                    lineHeight: "27px",
                }}>
                    Share New Cards By Email
                </Typography>
                <Box sx={{ position: 'relative', marginTop: '20px' }}>
                    <Label sx={{
                        color: "#172B4D",
                        fontWeight: "600",
                        fontSize: "14px",
                        marginTop: '4px'
                    }}>Send New Card By Email *</Label>

                    <CustomBox>
                        {emails.map((email, index) => (
                            <Chip
                                key={index}
                                label={email}
                                onDelete={() => handleDeleteEmail(email)}
                                sx={{
                                    margin: '4px',
                                    backgroundColor: '#E1E9FF',
                                    color: '#172B4D',
                                    fontWeight: '500'
                                }}
                            />
                        ))}
                        <TextField
                            value={currentEmail}
                            onChange={handleEmailChange}
                            onKeyPress={handleAddEmail}
                            placeholder="Type an email and press Enter."
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            sx={{
                                flex: 1,
                                margin: '4px',
                                minWidth: '240px',
                            }}
                        />
                    </CustomBox>
                </Box>
                <InputField
                    id="Meesage"
                    label="Meesage"
                    value={subscriptionData?.description}
                    placeholder='Please enter your Meesage.'
                    onChange={handleChange}
                    multiline
                    required
                    rows={4}
                    sx={{
                        fontWeight: "500",
                        fontSize: { xs: "14px", sm: "16px" },
                        lineHeight: "25px",
                        marginTop: "30px",
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: { xs: "100%", sm: "70%", md: "50%" },
                    marginRight: "15px",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: { xs: "10px", sm: "0" },
                    marginBottom: "5px"
                }}>
                    <WhiteButton
                        onClick={handleClose}
                    >
                        Cancel
                    </WhiteButton>
                    <BlueButton
                        onClick={handleSave}
                    >
                        Send
                    </BlueButton>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default NewCard;
