import { Button, styled } from '@mui/material';
import colorConfigs from '../../../configs/colorConfigs';

const WhiteButton = styled(Button)(() => ({
    backgroundColor: 'white',
    borderRadius: '8px',
    width: '140px',
    padding: '10px 20px',
    '&:hover': { backgroundColor: '#E6E9F2', },
    fontWeight: '600',
    fontSize: "15px",
    color: colorConfigs?.sidebar?.active,
    border: `1px solid ${colorConfigs?.sidebar?.active}`,
    height: '48px',
    textTransform: 'capitalize',

}));

export default WhiteButton