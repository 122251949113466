import React, { useRef } from 'react';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import colorConfigs from '../../configs/colorConfigs';
import CardIcon from "../../assets/svg/CardIcon";
import InputField from "../../components/InputField"
import { FiSearch } from "react-icons/fi";
import DynamicTable from "../../pages/table/table";
import { useState } from "react";
import { Switch } from "antd";
import { LuEye } from "react-icons/lu";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin5Line } from "react-icons/ri";
import Status from "../status/status";
import AddButton from '../../components/common/Buttons/AddButton';
import NewCard from './GenerateNewCard';

const hoverEffectStyle = {
    cursor: "pointer",
    borderRadius: 25,
    height: '60px',
    width: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
        transform: 'scale(1.2)',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
    }
};

const style = {
    color: "#172B4D",
    fontSize: "14.5px",
    fontWeight: "600",
    lineHeight: "20px",

};

function Cards() {

    const [addNewCard, setAddNewCard] = useState(false);

    const handleOpenDialog = () => {
        setAddNewCard(true);
    };

    const handleCloseDialog = () => {
        setAddNewCard(false);
    };

    const items = [
        { title: 'All Cards', value: 250, icon: <CardIcon sx={{ size: "50px" }} /> },
        { title: 'New Cards', value: 150, icon: <CardIcon /> },
        { title: 'Active Cards', value: 100, icon: <CardIcon /> },
        { title: 'Inactive Cards', value: 35 }
    ];

    const currentPage = useRef(1);
    const currentLimit = useRef(10);
    let currentKeyword = ("");
    let currentStatus = ("all");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        currentPage.current = newPage;
    };

    const handleRowsChange = (rows) => {
        currentPage.current = 1;
        currentLimit.current = rows;
        setPage(1);
        setRowsPerPage(rows);
    };
    const data = [
        {
            id: 1,
            link: "/link8",
            userName: "Darlene Robertson",
            profileImage: "profile_url_here",
            createdDate: "October 6, 2024",
            status: "active",
            enabled: true,
        },
        {
            id: 2,
            link: "/#link8",
            userName: "-",
            profileImage: null,
            createdDate: "October 6, 2024",
            status: "inactive",
            enabled: true,
        },
    ];

    const columns = [
        {
            field: "id",
            headerName: "ID",
            flex: 1,
            style: style,
            minWidth: "50px",
            sort: true,
            align: "left",
            headerClassName: "super-app-theme--header",
        },
        {
            field: "link",
            headerName: "Link",
            style: style,
            flex: 1,
            minWidth: "100px",
            sort: true,
            align: "left",
            headerClassName: "super-app-theme--header",
        },
        {
            style: style,
            field: "userName",
            headerName: "User Name",
            flex: 1,
            minWidth: "150px",
            sort: true,
            align: "left",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <span style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'start',
                }}

                >
                    <img
                        style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            marginRight: "5px"
                        }}
                        src={params?.row?.owner_info?.profile_picture ? params?.row?.owner_info?.profile_picture : "https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"}
                        alt=""
                    />
                    <span>{params?.row?.userName} </span>
                </span>
            ),
        },
        {
            field: "createdDate",
            headerName: "Created Date",
            flex: 1,
            style: style,
            minWidth: "150px",
            sort: true,
            align: "left",
            headerClassName: "super-app-theme--header",
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            minWidth: "100px",
            style: style,
            sort: true,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <Status status={params.row.status} />

            ),
        },
        {
            field: "enabled",
            headerName: "Enable/Disable",
            flex: 1,
            style: style,
            minWidth: "100px",
            sort: true,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Switch
                        checked={params.row.enabled}
                        style={{
                            color: params.row.enabled ? "#0F5FCB" : "",
                        }}
                    />
                </div>
            ),
        },
        {
            field: "action",
            style: style,
            headerName: "Action",
            align: "center",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <Box
                    style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                    }}
                >
                    <LuEye size="22.5px" style={{ color: "#16151C", cursor: "pointer" }} />
                    <CiEdit size="22.5px" style={{ color: "#16151C", cursor: "pointer" }} />
                    <RiDeleteBin5Line size="22.5px" style={{ color: "#16151C", cursor: "pointer" }} />
                </Box>
            ),
        },
    ];


    return (
        <Box>
            {/* topside */}
            <Box>
                {/* titel and add btn */}
                <Box
                    item
                    xs={12}
                    sm={12}
                    md={9}
                    lg={9}
                    xl={9}
                    style={{
                        display: "flex",
                        gap: "14px",
                        marginTop: "-130px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        marginLeft: "6px",
                    }}>
                    <Box style={{
                        display: "flex",
                        flexDirection: 'row',
                        gap: "14px",
                    }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontFamily: "TT Drugs Trial",
                            fontSize: "24px",
                            fontWeight: "700",
                            lineHeight: "33px",
                            marginLeft: "10px"
                        }}>
                            All Cards
                        </span>
                    </Box>
                    <AddButton
                        onClick={() => {
                            handleOpenDialog(true)
                        }}
                    >
                        +{" "}{"Generate New Card"}
                    </AddButton>

                </Box>
            </Box>
            {/* cards */}
            <Box
                container
                spacing={2}
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: "8px",
                    gap: 2,
                    p: 1
                }}
            >
                {items.map((item, index) => (
                    <Box key={index} item xs={12} sm={6} md={3} lg={3} sx={{ width: { xs: '100%', sm: '48%', md: '23%' } }}>
                        <Card
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                p: 2,
                                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 4px',
                                borderRadius: "8px",
                                gap: 2,
                            }}
                        >
                            <CardContent
                                sx={{
                                    width: item.icon ? '70%' : '100%',
                                    textAlign: 'left',
                                    p: 0
                                }}
                            >
                                <Typography sx={{ color: '#172B4D', fontWeight: 800, fontSize: "14px", lineHeight: "18px" }} component="div">
                                    {item.title}
                                </Typography>
                                <Typography sx={{ color: colorConfigs.sidebar.active, fontWeight: 800, fontSize: "24px", lineHeight: "30.24px", mt: 1, ml: .5 }} component="div">
                                    {item.value}
                                </Typography>
                            </CardContent>

                            {item.icon ? (
                                <Box
                                // sx={{ ...hoverEffectStyle }}
                                >
                                    {item.icon}
                                </Box>
                            ) : (
                                <Box>
                                </Box>
                            )}
                        </Card>
                    </Box>
                ))}
            </Box>
            {/* search and filter */}
            <Box
                container
                spacing={1}
                style={{
                    display: "flex",
                    flexDirection: 'row',
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: "10px",
                    marginTop: "10px",
                    backgroundColor: "white",
                    height: "70px",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px"

                }}>
                <Box style={{
                    minWidth: '150px',

                }}>
                    <span style={{
                        color: "#172B4D",
                        fontFamily: "TT Drugs Trial",
                        fontSize: "20px",
                        fontWeight: "700",
                        lineHeight: "27px",
                    }}>                              All Cards
                    </span>
                </Box>
                <Box style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    <InputField
                        id="Search"
                        placeholder="Search"
                        type="text"
                        value={currentKeyword}
                        startAdornment={<FiSearch size={24} color="#88939D" />}
                        variant="outlined"
                        sx={{
                            width: "350px",
                            fontWeight: "500",
                            fontSize: "18px",
                        }}
                    />
                    {/* <Box>    Filter</Box> */}
                </Box>

            </Box>
            <DynamicTable
                columns={columns}
                data={data}
                count={data?.length}
                page={page}
                rowsPerPage={rowsPerPage}
                pageChange={handlePageChange}
                rowsChange={handleRowsChange}
                pagetype="creditCards"
            />

            <NewCard addNewCard={addNewCard} onClose={handleCloseDialog} />

        </Box>

    );
}

export default Cards;
