import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  testRedux: "test11",
  openDrawer: true,
  selected: "",
  appState: "",
  userData: {},
  subTypes: [],
};

export const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setTestRedux: (state, action) => {
      state.testRedux = action.payload;
    },
    setOpenDrawer: (state, action) => {
      state.openDrawer = action.payload;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    setAppState: (state, action) => {
      state.appState = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setSubTypes: (state, action) => {
      state.subTypes = action.payload;
    },


  },
});

export const {
  setUserData,
  setTestRedux,
  setOpenDrawer,
  setSelected,
  setAppState,
  setSubTypes

} = appStateSlice.actions;

export default appStateSlice.reducer;
