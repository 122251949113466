import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        {...props}
    >
        <path
            stroke="#F5F5F5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.139 8.502v5.717M10.03 5.766v8.452M13.854 11.523v2.695"
        />
        <path
            stroke="#F5F5F5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.902 1.667h-7.81c-2.722 0-4.428 1.927-4.428 4.654v7.358c0 2.728 1.698 4.655 4.429 4.655h7.81c2.73 0 4.428-1.927 4.428-4.655V6.321c0-2.727-1.699-4.654-4.429-4.654Z"
            clipRule="evenodd"
        />
    </svg>
)
const ChartIcon = memo(SvgComponent)
export default ChartIcon