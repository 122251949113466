import * as React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { styled } from '@mui/material'

const InputField = styled(FormControl)(({ styles }) => ({
  ...styles
}))

const DropDown = (props) => {
  const { styles, ...otherProps } = props

  return (
    <div>
      <InputField
        autoFocus
        id='outlined-basic'
        styles={styles}
        {...otherProps}
        sx={props.sx}
      >
        <InputLabel id='demo-simple-select-helper-label'>
          {props.label}
        </InputLabel>
        <Select
          labelId='demo-simple-select-helper-label'
          label={props.label}
          style={{ color: props.color }}
          autoFocus
          value={props.value} // Use the value prop to control the selected value
          onChange={props.onChange} // Use handleChange from props
        >
          {props.data.map((d) => (
            <MenuItem key={d} value={d}>
              {d}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{props.formHelperText}</FormHelperText>
      </InputField>
    </div>
  )
}

export default DropDown
