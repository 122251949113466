import React, { memo, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
    Select,
    MenuItem,
    OutlinedInput,
    ListItemText,
    TextField,
    RadioGroup,
    Radio,
    Rating,
    FormControl,
    InputLabel,
    Grid,
    createTheme,
    ThemeProvider,
    Box,
    Divider
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Helper function for setting grid breakpoints
const getGridWidths = (gridWidth) => {
    const { xs, sm, md, lg, xl } = gridWidth || {};
    return {
        xs: xs !== undefined ? xs : 12,
        sm: sm !== undefined ? sm : xs !== undefined ? xs : 12,
        md: md !== undefined ? md : sm !== undefined ? sm : xs !== undefined ? xs : 12,
        lg: lg !== undefined ? lg : md !== undefined ? md : sm !== undefined ? sm : xs !== undefined ? xs : 12,
        xl: xl !== undefined ? xl : lg !== undefined ? lg : md !== undefined ? md : sm !== undefined ? sm : xs !== undefined ? xs : 12,
    };
};

// SVG component for the filter icon
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            stroke="#16151C"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M3 6.188h7M3 12.188h9M19 12.188h2M14 6.188h7M13 18.188h7M3 18.188h3"
        />
        <circle cx={8} cy={18.188} r={2} stroke="#16151C" strokeWidth={1.5} />
        <circle cx={17} cy={12.188} r={2} stroke="#16151C" strokeWidth={1.5} />
        <circle cx={12} cy={6.188} r={2} stroke="#16151C" strokeWidth={1.5} />
    </svg>
);
const FilterIcon = memo(SvgComponent);

function DynamicFilter({ filterData, inputsStyle, mainColor, children, width, onApply }) {
    // Create a MUI theme with custom mainColor for dynamic theming
    const theme = mainColor ? createTheme({
        palette: { primary: { main: mainColor }, secondary: { main: '#dff' } },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: { color: mainColor, "&.Mui-checked": { color: mainColor } }
                }
            },
            MuiRadio: {
                styleOverrides: {
                    root: { color: mainColor, "&.Mui-checked": { color: mainColor } }
                }
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: { color: mainColor, "&.Mui-checked": { color: mainColor } }
                }
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& label.Mui-focused': { color: mainColor },
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': { borderColor: mainColor },
                            '&.Mui-checked': { borderColor: mainColor }
                        }
                    }
                }
            },
            MuiSelect: {
                styleOverrides: {
                    root: {
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': { borderColor: mainColor },
                            '&.Mui-checked': { borderColor: mainColor }
                        }
                    }
                }
            },
            MuiRating: {
                styleOverrides: {
                    iconFilled: { color: mainColor },
                    "&.Mui-checked": { color: mainColor }
                }
            },
        },
    }) : createTheme();

    // State declarations for various filter options and dialog visibility
    const [open, setOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [selectedMenuItems, setSelectedMenuItems] = useState({});
    const [selectedSingleMenuItem, setSelectedSingleMenuItem] = useState({});
    const [selectedDates, setSelectedDates] = useState({});
    const [selectedTimes, setSelectedTimes] = useState({});
    const [selectedDateTimes, setSelectedDateTimes] = useState({});
    const [textInputs, setTextInputs] = useState({});
    const [selectedRadio, setSelectedRadio] = useState({});
    const [selectedRatings, setSelectedRatings] = useState({});
    const [clearConfirmOpen, setClearConfirmOpen] = useState(false);
    const [hasActiveFilters, setHasActiveFilters] = useState(false);

    const [tempState, setTempState] = useState({});

    // Custom styling for input components
    const inputStyle = {
        "& .MuiOutlinedInput-root": {
            height: inputsStyle?.height || '50px',
            "& input": { padding: "10.5px 14px" },
            "& fieldset": {
                border: inputsStyle?.border || '1px solid #BBC1CE',
                borderRadius: inputsStyle?.borderRadius || '8px',
            },
            "&:hover fieldset": {
                border: inputsStyle?.border || '1px solid #BBC1CE',
                borderRadius: inputsStyle?.borderRadius || '8px',
            },
            "&.Mui-focused fieldset": {
                border: inputsStyle?.border || '1px solid #BBC1CE',
                borderRadius: inputsStyle?.borderRadius || '8px',
            },
            "&.Mui-disabled": {
                "& fieldset": { border: "1px solid #BBC1CE" },
                "&:hover fieldset": { border: "1px solid #BBC1CE" },
            },
        },
        "& .MuiInputLabel-root": {
            color: "#7A869A",
            fontSize: inputsStyle?.fontSize || '14px',
            top: "50%",
            transform: "translateY(-50%)",
            left: "12px",
        },
        "& .MuiInputLabel-shrink": {
            top: "0px",
            fontSize: '12px',
            left: '12px',
            background: '#fff',
        },
        "& label.Mui-focused": {
            color: "#000",
            fontSize: '12px',
            fontWeight: "500",
        },
        width: "100%",
    };

    // Function to open the dialog and set temporary filter state
    const handleClickOpen = () => {
        setTempState({
            selectedOptions,
            selectedMenuItems,
            selectedSingleMenuItem,
            selectedDates,
            selectedTimes,
            selectedDateTimes,
            textInputs,
            selectedRadio,
            selectedRatings,
        });
        setOpen(true);
    };

    // Close dialog and revert to temporary state
    const handleClose = () => {
        setSelectedOptions(tempState.selectedOptions);
        setSelectedMenuItems(tempState.selectedMenuItems);
        setSelectedSingleMenuItem(tempState.selectedSingleMenuItem);
        setSelectedDates(tempState.selectedDates);
        setSelectedTimes(tempState.selectedTimes);
        setSelectedDateTimes(tempState.selectedDateTimes);
        setTextInputs(tempState.textInputs);
        setSelectedRadio(tempState.selectedRadio);
        setSelectedRatings(tempState.selectedRatings);
        setOpen(false);
    };

    // Flatten the filter data for easy manipulation
    const flattenFilterData = (filterData) => {
        const flattened = {};
        Object.values(filterData).forEach((category) => {
            category.forEach((field) => {
                flattened[field.name] = field;
            });
        });
        return flattened;
    };

    // Apply selected filters and convert field values as needed
    const handleApply = () => {
        const flattenedFilterData = flattenFilterData(filterData);
        const activeValues = {
            ...Object.fromEntries(
                Object.entries(selectedOptions).filter(([key, value]) =>
                    Object.values(value).some((val) => val)
                )
            ),
            ...Object.fromEntries(
                Object.entries(selectedMenuItems).filter(([key, value]) => value.length > 0)
            ),
            ...Object.fromEntries(
                Object.entries(selectedSingleMenuItem).filter(([key, value]) => value !== '')
            ),
            ...Object.fromEntries(
                Object.entries(selectedDates).filter(([key, value]) => value !== null)
            ),
            ...Object.fromEntries(
                Object.entries(selectedTimes).filter(([key, value]) => value !== null)
            ),
            ...Object.fromEntries(
                Object.entries(selectedDateTimes).filter(([key, value]) => value !== null)
            ),
            ...Object.fromEntries(
                Object.entries(textInputs).filter(([key, value]) => value !== '')
            ),
            ...Object.fromEntries(
                Object.entries(selectedRadio).filter(([key, value]) => value !== '')
            ),
            ...Object.fromEntries(
                Object.entries(selectedRatings).filter(([key, value]) => value > 0)
            ),
        };

        const convertedValues = Object.fromEntries(
            Object.entries(activeValues).map(([key, value]) => {
                const fieldData = flattenedFilterData[key];
                if (fieldData?.output === 'timestamp') {
                    return [key, value ? new Date(value).getTime() : null];
                } else if (fieldData?.output === 'number') {
                    return [key, Number(value) || 0];
                }
                return [key, value];
            })
        );

        setOpen(false);
        return onApply ? onApply(convertedValues) : null;
    };

    // Check if a field should render based on dependencies
    const shouldRenderField = (fieldData) => {
        const { dependsOn } = fieldData;
        if (!dependsOn) return true;
        const { field, value } = dependsOn;
        const dependentFieldValue = getFieldValue(field);
        return dependentFieldValue === value;
    };

    // Check if a field should be disabled based on condition
    const shouldDisableField = (fieldData) => {
        const { disable } = fieldData;
        if (!disable) return false;
        const { field, condition } = disable;
        const dependentFieldValue = getFieldValue(field);
        if (typeof condition === 'function') {
            return condition(dependentFieldValue);
        }
        return dependentFieldValue === disable.value;
    };

    // Get the value of a specific field by its key
    const getFieldValue = (fieldKey) => {
        return selectedOptions[fieldKey] || selectedMenuItems[fieldKey] || selectedSingleMenuItem[fieldKey]
            || textInputs[fieldKey] || selectedRadio[fieldKey] || selectedRatings[fieldKey]
            || selectedDates[fieldKey] || selectedTimes[fieldKey] || selectedDateTimes[fieldKey] || null;
    };

    // Handlers for various filter component types
    const handleCheckboxChange = (filterCategory, value) => {
        setSelectedOptions((prevState) => ({
            ...prevState,
            [filterCategory]: {
                ...prevState[filterCategory],
                [value]: !prevState[filterCategory]?.[value],
            },
        }));
    };

    const handleMenuSelectChange = (filterCategory, event) => {
        const { target: { value } } = event;
        setSelectedMenuItems({
            ...selectedMenuItems,
            [filterCategory]: typeof value === 'string' ? value.split(',') : value,
        });
    };

    const handleSingleMenuSelectChange = (filterCategory, event) => {
        const { value } = event.target;
        setSelectedSingleMenuItem({
            ...selectedSingleMenuItem,
            [filterCategory]: value,
        });
    };

    const handleDateChange = (filterCategory, newValue) => {
        setSelectedDates((prevState) => ({
            ...prevState,
            [filterCategory]: newValue ? newValue : null,
        }));
    };

    const handleTimeChange = (filterCategory, newValue) => {
        setSelectedTimes((prevState) => ({
            ...prevState,
            [filterCategory]: newValue ? newValue : null,
        }));
    };

    const handleDateTimeChange = (filterCategory, newValue) => {
        setSelectedDateTimes((prevState) => ({
            ...prevState,
            [filterCategory]: newValue ? newValue : null,
        }));
    };

    const handleTextInputChange = (filterCategory, event) => {
        const { value } = event.target;
        setTextInputs((prevState) => ({
            ...prevState,
            [filterCategory]: value,
        }));
    };

    const handleRadioChange = (filterCategory, event) => {
        const { value } = event.target;
        setSelectedRadio((prevState) => ({
            ...prevState,
            [filterCategory]: value,
        }));
    };

    const handleRatingChange = (filterCategory, newValue) => {
        setSelectedRatings((prevState) => ({
            ...prevState,
            [filterCategory]: newValue,
        }));
    };

    // Clear all filters after confirming with the user
    const handleClearConfirm = () => {
        setClearConfirmOpen(true);
    };

    const handleConfirmClear = () => {
        setSelectedOptions({});
        setSelectedMenuItems({});
        setSelectedSingleMenuItem({});
        setSelectedDates({});
        setSelectedTimes({});
        setSelectedDateTimes({});
        setTextInputs({});
        setSelectedRadio({});
        setSelectedRatings({});
        setClearConfirmOpen(false);
    };

    const handleCancelClear = () => {
        setClearConfirmOpen(false);
    };

    // Update the active filters status based on the selected filters
    const checkActiveFilters = () => {
        const anyActive = Object.values({
            ...selectedOptions,
            ...selectedMenuItems,
            ...selectedSingleMenuItem,
            ...selectedDates,
            ...selectedTimes,
            ...selectedDateTimes,
            ...textInputs,
            ...selectedRadio,
            ...selectedRatings
        }).some(value => {
            if (Array.isArray(value)) return value.length > 0;
            return value !== null && value !== '';
        });
        setHasActiveFilters(anyActive);
    };

    useEffect(() => {
        checkActiveFilters();
    }, [selectedOptions, selectedMenuItems, selectedSingleMenuItem, selectedDates, selectedTimes, selectedDateTimes, textInputs, selectedRadio, selectedRatings]);

    return (
        <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {children ? (
                        React.cloneElement(children, { onClick: handleClickOpen })
                    ) : (
                        <Button
                            sx={{
                                color: '#000',
                                textTransform: 'capitalize',
                                fontSize: 16,
                                height: 45,
                                fontWeight: 500,
                                width: 100,
                                borderRadius: 3,
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                border: '1px solid #BBC1CE',
                            }}
                            onClick={handleClickOpen}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <FilterIcon />
                            </Box>
                            <Typography sx={{ ml: 1, fontWeight: 300, fontSize: 16, color: '#1C1D22' }}>
                                Filter
                            </Typography>
                        </Button>
                    )}

                    <Dialog open={open} onClose={handleClose}
                        sx={{
                            '& .MuiPaper-root': {
                                width: width,
                                borderRadius: 4,
                                mx: 'auto',
                            },
                        }} >
                        <DialogTitle>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography sx={{ fontWeight: 700, fontSize: 18, color: '#1C1D22' }}>
                                    Filter
                                </Typography>
                                {hasActiveFilters && (
                                    <Typography
                                        onClick={handleClearConfirm}
                                        sx={{
                                            ml: 1,
                                            fontWeight: 600,
                                            fontSize: 14,
                                            color: mainColor ? mainColor : '#1C1D22',
                                            textDecoration: 'underline',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Clear Filter
                                    </Typography>
                                )}
                            </Box>
                            <Divider sx={{ my: 1 }} />
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>  {/* Parent Grid Container */}
                                {Object.keys(filterData).map((filterCategory) => {
                                    const fieldArray = filterData[filterCategory];
                                    return (
                                        <Grid container spacing={1} item xs={12} key={filterCategory}> {/* Category-level Container */}
                                            <Grid item xs={12}>
                                                <Typography sx={{ color: '#16151C', fontSize: 16, fontWeight: 600, mb: 0.5 }}>{filterCategory}</Typography> {/* Category Title */}
                                            </Grid>
                                            {fieldArray.map((fieldData, index) => {
                                                if (!shouldRenderField(fieldData)) return null;
                                                const isDisabled = shouldDisableField(fieldData);
                                                const gridProps = getGridWidths(fieldData.gridWidth);

                                                return (
                                                    <Grid sx={{ p: 0, m: 0 }} item {...gridProps} key={index}> {/* Grid for each input */}
                                                        {/* Render each filter component based on field type */}
                                                        {fieldData.type === 'radio' && (
                                                            <RadioGroup
                                                                row={fieldData.direction === 'horizontal'}
                                                                value={selectedRadio[fieldData.name] || ''}
                                                                onChange={(event) => handleRadioChange(fieldData.name, event)}
                                                                sx={{ p: 0, m: 0 }}
                                                            >
                                                                {fieldData.list.map(({ label, value }, order) => (
                                                                    <FormControlLabel
                                                                        key={value}
                                                                        value={value}
                                                                        sx={{ p: 0, m: 0 }}
                                                                        control={<Radio sx={{
                                                                            pl: order === 0 && fieldData.direction === 'horizontal' ? 0 : fieldData.direction !== 'horizontal' ? 0 : undefined,
                                                                            m: 0,
                                                                        }}
                                                                            disabled={isDisabled} />}
                                                                        label={label}
                                                                    />
                                                                ))}
                                                            </RadioGroup>
                                                        )}
                                                        {fieldData.type === 'checkbox' && (
                                                            <FormGroup row={fieldData.direction === 'horizontal'}>
                                                                {fieldData.list.map(({ label, value }) => (
                                                                    <FormControlLabel
                                                                        key={value}
                                                                        control={
                                                                            <Checkbox
                                                                                checked={selectedOptions[fieldData.name]?.[value] || false}
                                                                                onChange={() => handleCheckboxChange(fieldData.name, value)}
                                                                                disabled={isDisabled}
                                                                            />
                                                                        }
                                                                        label={label}
                                                                    />
                                                                ))}
                                                            </FormGroup>
                                                        )}
                                                        {fieldData.type === 'text' && (
                                                            <TextField
                                                                label={fieldData.placeholder}
                                                                value={textInputs[fieldData.name] || ''}
                                                                onChange={(event) => handleTextInputChange(fieldData.name, event)}
                                                                fullWidth
                                                                variant="outlined"
                                                                sx={inputStyle}
                                                                disabled={isDisabled}
                                                            />
                                                        )}
                                                        {fieldData.type === 'menuWithSelect' && (
                                                            <FormControl fullWidth disabled={isDisabled} sx={inputStyle}>
                                                                <InputLabel>{fieldData.placeholder}</InputLabel>
                                                                <Select
                                                                    multiple
                                                                    sx={inputStyle}
                                                                    value={selectedMenuItems[fieldData.name] || []}
                                                                    onChange={(event) => handleMenuSelectChange(fieldData.name, event)}
                                                                    input={<OutlinedInput label={fieldData.placeholder} />}
                                                                    renderValue={(selected) =>
                                                                        fieldData.list
                                                                            .filter(({ value }) => selected.includes(value))
                                                                            .map(({ label }) => label)
                                                                            .join(', ')
                                                                    }
                                                                >
                                                                    {fieldData.list.map(({ label, value }) => (
                                                                        <MenuItem key={value} value={value}>
                                                                            <Checkbox checked={selectedMenuItems[fieldData.name]?.indexOf(value) > -1} />
                                                                            <ListItemText primary={label} />
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                        {fieldData.type === 'menu' && (
                                                            <FormControl sx={inputStyle} fullWidth disabled={isDisabled}>
                                                                <InputLabel>{fieldData.placeholder}</InputLabel>
                                                                <Select
                                                                    value={selectedSingleMenuItem[fieldData.name] || ''}
                                                                    onChange={(event) => handleSingleMenuSelectChange(fieldData.name, event)}
                                                                    input={<OutlinedInput label={fieldData.placeholder} />}
                                                                >
                                                                    {fieldData.list.map(({ label, value }) => (
                                                                        <MenuItem key={value} value={value}>
                                                                            {label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                        {fieldData.type === 'date' && (
                                                            <MobileDatePicker
                                                                sx={inputStyle}
                                                                label={fieldData.placeholder}
                                                                inputFormat="MM/DD/YYYY"
                                                                value={selectedDates[fieldData.name] || null}
                                                                onChange={(newValue) => handleDateChange(fieldData.name, newValue)}
                                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                                                disabled={isDisabled}
                                                            />
                                                        )}
                                                        {fieldData.type === 'time' && (
                                                            <MobileTimePicker
                                                                sx={inputStyle}
                                                                label={fieldData.placeholder}
                                                                value={selectedTimes[fieldData.name] || null}
                                                                onChange={(newValue) => handleTimeChange(fieldData.name, newValue)}
                                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                                                disabled={isDisabled}
                                                            />
                                                        )}
                                                        {fieldData.type === 'dateAndTime' && (
                                                            <MobileDateTimePicker
                                                                sx={inputStyle}
                                                                label={fieldData.placeholder}
                                                                value={selectedDateTimes[fieldData.name] || null}
                                                                onChange={(newValue) => handleDateTimeChange(fieldData.name, newValue)}
                                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                                                disabled={isDisabled}
                                                            />
                                                        )}
                                                        {fieldData.type === 'rating' && (
                                                            <Rating
                                                                value={selectedRatings[fieldData.name] || 0}
                                                                onChange={(event, newValue) => handleRatingChange(fieldData.name, newValue)}
                                                                disabled={isDisabled}
                                                            />
                                                        )}
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', px: 3, py: 2 }}>
                            <Button sx={{ border: '1px solid #BBC1CE', borderRadius: 3, color: '#1C1D22', fontSize: 16, fontWeight: 500, height: 45, textTransform: 'capitalize', width: '47%' }} onClick={handleClose} >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleApply}
                                sx={{
                                    background: mainColor ? mainColor : 'blue',
                                    border: '1px solid #BBC1CE',
                                    borderRadius: 3,
                                    color: '#fff',
                                    fontSize: 16,
                                    fontWeight: 500,
                                    height: 45,
                                    textTransform: 'capitalize',
                                    width: '47%',
                                    '&:hover': {
                                        background: 'transparent',
                                        color: mainColor ? mainColor : 'blue',
                                        border: `1px solid ${mainColor ? mainColor : 'blue'}`,
                                    },
                                }}
                            >
                                Apply
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* Confirmation Dialog */}
                    <Dialog open={clearConfirmOpen} onClose={handleCancelClear} sx={{
                        '& .MuiPaper-root': {
                            borderRadius: 4,
                            p: 2
                        },
                    }}>
                        <DialogTitle sx={{ fontWeight: 700, fontSize: 18, color: '#1C1D22' }}>Confirm Clear Filters</DialogTitle>
                        <DialogContent>
                            <Typography>Are you sure you want to clear all filters?</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button sx={{ border: '1px solid #BBC1CE', borderRadius: 3, color: '#1C1D22', fontSize: 16, fontWeight: 500, height: 45, textTransform: 'capitalize', width: '47%' }} onClick={handleCancelClear}>Cancel</Button>
                            <Button onClick={handleConfirmClear} sx={{
                                background: mainColor ? mainColor : 'blue',
                                border: '1px solid #BBC1CE',
                                borderRadius: 3,
                                color: '#fff',
                                fontSize: 16,
                                fontWeight: 500,
                                textTransform: 'capitalize',
                                height: 45, width: '47%',
                                '&:hover': {
                                    background: 'transparent',
                                    color: mainColor ? mainColor : 'blue',
                                    border: `1px solid ${mainColor ? mainColor : 'blue'}`,
                                },
                            }} >
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                </LocalizationProvider>
        </>
    );
}

export default DynamicFilter;