import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={48}
        height={48}
        fill="none"
        {...props}
    >
        <path
            fill="#E8EDF2"
            d="M35.407 14.775a.698.698 0 0 1-.508-.218L24 3.128 13.174 14.482a.704.704 0 1 1-1.017-.97L23.49 1.623a.704.704 0 0 1 1.018 0l11.408 11.963a.704.704 0 0 1-.51 1.188Z"
        />
        <path
            fill="#554E56"
            d="M24 4.453a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
        />
        <path
            fill="#F5F8F9"
            d="M39.61 14.86v25.513l-6.925 6.924H9.797A1.406 1.406 0 0 1 8.39 45.89V14.859a1.406 1.406 0 0 1 1.406-1.406h28.406a1.406 1.406 0 0 1 1.406 1.406Z"
        />
        <path
            fill="#D9E7EC"
            d="M38.203 13.453h-2.812a1.407 1.407 0 0 1 1.406 1.406v22.522a1.78 1.78 0 0 1-1.574 1.769l-2.007.233a2.19 2.19 0 0 0-1.937 2.175v5.739h1.406l6.924-6.924V14.859a1.406 1.406 0 0 0-1.406-1.406Z"
        />
        <path
            fill="#0F5FCB"
            d="M39.61 14.86v9.843H8.39V14.86a1.406 1.406 0 0 1 1.407-1.406h28.406a1.406 1.406 0 0 1 1.406 1.406Z"
        />
        <path
            fill="#0F5FCB"
            d="M38.203 13.453h-2.812a1.406 1.406 0 0 1 1.406 1.406v9.844h2.812V14.86a1.406 1.406 0 0 0-1.406-1.406Z"
        />
        <path
            fill="#CECECE"
            d="M32.685 47.297v-5.518a1.406 1.406 0 0 1 1.406-1.406h5.518l-6.924 6.924Z"
        />
        <path
            fill="#F5F8F9"
            d="M16.304 16.553c.924 0 1.856.583 1.856 1.65 0 1.73-2.462 2.296-2.462 3.02v.072h2.088c.199 0 .375.248.375.535 0 .287-.175.55-.375.55H14.95c-.223 0-.541-.152-.541-.383v-.774c0-1.25 2.51-1.969 2.51-2.98 0-.255-.16-.563-.607-.563-.318 0-.597.16-.597.598 0 .231-.247.454-.656.454-.319 0-.562-.143-.562-.646.007-.975.9-1.532 1.808-1.532Zm3.466 3.993v-2.112c0-1.37.853-1.88 1.953-1.88s1.96.51 1.96 1.88v2.112c0 1.37-.86 1.88-1.96 1.88s-1.953-.51-1.953-1.88Zm2.67-2.112c0-.55-.271-.797-.717-.797-.447 0-.71.248-.71.797v2.112c0 .55.263.797.71.797.446 0 .717-.247.717-.797v-2.112Zm4.747-1.88c.925 0 1.857.582 1.857 1.65 0 1.728-2.462 2.294-2.462 3.02v.071h2.088c.198 0 .375.248.375.535 0 .287-.176.55-.375.55h-2.835c-.224 0-.542-.152-.542-.383v-.774c0-1.25 2.51-1.969 2.51-2.98 0-.255-.16-.563-.606-.563-.319 0-.597.16-.597.598 0 .231-.248.454-.657.454-.318 0-.557-.143-.557-.646.002-.975.895-1.532 1.802-1.532Zm3.47 3.992v-2.112c0-1.37.852-1.88 1.952-1.88s1.96.51 1.96 1.88v2.112c0 1.37-.86 1.88-1.96 1.88s-1.953-.51-1.953-1.88Zm2.67-2.112c0-.55-.272-.797-.718-.797-.446 0-.71.248-.71.797v2.112c0 .55.264.797.71.797.446 0 .717-.247.717-.797v-2.112Z"
        />
        <path
            fill="#B6C4CF"
            d="M13.078 31.266a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM20.36 31.266a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM27.64 31.266a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM34.922 31.266a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM13.078 37.828a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM20.36 37.828a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
        />
        <path
            fill="#0F5FCB"
            d="M27.64 37.828a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
        />
        <path
            fill="#B6C4CF"
            d="M34.922 37.828a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM13.078 44.39a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM20.36 44.39a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM27.64 44.39a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
        />
    </svg>
)
const CardIcon = memo(SvgComponent)
export default CardIcon
