import {
    Box, Button, Card, CardContent, Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import colorConfigs from '../../configs/colorConfigs';
import CardIcon from "../../assets/svg/CardIcon"; // Default icon
import InputField from "../../components/InputField"
import { FiSearch } from "react-icons/fi";
import DynamicTable from "../table/table";
import { useState } from "react";
import { Switch } from "antd";
import { LuEye } from "react-icons/lu";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin5Line } from "react-icons/ri";
import Status from "../status/status";
import AddNewSubscription from './AddNewSubscriptionType';
import AddButton from '../../components/common/Buttons/AddButton';
import axios from 'axios';
import { API_URL } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setSubTypes } from "../../redux/features/appStateSlice";
import deletee from "../../assets/images/delete.png";
import { message } from "antd";

const hoverEffectStyle = {
    cursor: "pointer",
    borderRadius: 25,
    height: '60px',
    width: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
        transform: 'scale(1.2)',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
    }
};

const style = {
    color: "#172B4D",
    fontSize: "14.5px",
    fontWeight: "600",
    lineHeight: "20px",

};

function Subscription() {

    const subscriptionTypes = useSelector((state) => state.appState.subTypes);
    const [activeButton, setActiveButton] = useState("Subscription Types");
    const [addNewSubscription, setAddNewSubscription] = useState(false);
    const [loading, setLoading] = useState(true);
    const [types, settTypes] = useState(subscriptionTypes);
    const [count, setCount] = useState(0);
    const [deletedItemId, setDeletedItemId] = useState();
    const [dataToEdit, setDataToEdit] = useState();
    const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);

    const currentPage = useRef(1);
    const currentLimit = useRef(10);
    let currentKeyword = ("");
    let currentStatus = ("all");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const dispatch = useDispatch();
    const [action, setAction] = useState("add");
    const [updating, setUpdating] = useState(false);
    const [updatingRowId, setUpdatingRowId] = useState(null);


    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        currentPage.current = newPage;
    };

    const handleRowsChange = (rows) => {
        currentPage.current = 1;
        currentLimit.current = rows;
        setPage(1);
        setRowsPerPage(rows);
    };

    const handleOpenDialog = () => {
        setAddNewSubscription(true);
    };

    const handleCloseDialog = () => {
        setAddNewSubscription(false);
    };

    const getSubscriptiontypes = async () => {
        setLoading(true);
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${API_URL}/subscription-types`,
            headers: {
                "Content-Type": "application/json",
            },
        };
        axios
            .request(config)
            .then((response) => {
                const parsedData = JSON.parse(response?.data);
                const subTypes = parsedData?.data?.data;
                console.log(parsedData);
                setCount(parsedData?.data?.count)
                dispatch(setSubTypes(subTypes));
                settTypes(subTypes);
                setLoading(false);
            })
            .catch((error) => {
                console.log("Error Get data: ", error);
                setLoading(false);
            });
    }
    useEffect(() => {
        getSubscriptiontypes()
    }, [])

    const handleEnableDisableChange = async (id, value) => {
        setUpdatingRowId(id);
        let config = {
            method: "put",
            maxBodyLength: Infinity,
            url: `${API_URL}/subscription-types/${id}`,
            headers: {
                "Content-Type": "application/json",
            },
            data: { is_enabled: value }
        };
        axios
            .request(config)
            .then((response) => {
                getSubscriptiontypes()
                setUpdatingRowId(null);
            })
            .catch((error) => {
                console.log("Error update data: ", error);
                setUpdating(false);
            });
    }

    const DeleteSubscriptiontypes = async (id) => {
        setLoading(true);
        let config = {
            method: "delete",
            maxBodyLength: Infinity,
            url: `${API_URL}/subscription-types/${id}`,
            headers: {
                "Content-Type": "application/json",
            },
        };
        axios
            .request(config)
            .then((response) => {
                getSubscriptiontypes()
                setLoading(false);
                setDeleteConfirmationDialogOpen(false)
                message.success("The type was deleted successfully.");
            })
            .catch((error) => {
                console.log("Error delete data: ", error);
                setLoading(false);
            });
    }

    const handleDeleteConfirmation = () => {
        DeleteSubscriptiontypes(deletedItemId);
    };

    const data = activeButton === "Subscription Types" ?
        types : [
            {
                id: 1,
                user: "Darlene Robertson",
                email: "Darlene@gmail.com",
                subscriptionType: "Monthly",
                startEndDate: "Oct 6, 2024 - Nov 6, 2024",
                renewDate: "Nov 6, 2024",
                status: "unpaid",
                enabled: true,
            },
            {
                id: 2,
                user: "John Doe",
                email: "Darlene@gmail.com",
                subscriptionType: "Monthly",
                startEndDate: "Oct 6, 2024 - Nov 6, 2024",
                renewDate: "Nov 6, 2024",
                status: "paid",
                enabled: true,
            },
        ]

    const columns =
        activeButton === "Subscription Types" ?
            [
                {
                    field: "name",
                    headerName: "Name",
                    flex: 1,
                    minWidth: "100px",
                    sort: true,
                    editable: true,
                    align: "left",
                    style: style,
                    headerClassName: "super-app-theme--header",
                },
                {
                    field: "user_count",
                    headerName: "Users",
                    flex: 1,
                    minWidth: "50px",
                    sort: true,
                    editable: true,
                    style: style,
                    align: "left",
                    headerClassName: "super-app-theme--header",
                },
                {
                    field: "price",
                    headerName: "Price",
                    flex: 1,
                    minWidth: "75px",
                    sort: true,
                    editable: true,
                    style: style,
                    align: "left",
                    headerClassName: "super-app-theme--header",
                },
                {
                    field: "billing_frequency",
                    headerName: "Billing Frequency",
                    flex: 1,
                    minWidth: "180px",
                    sort: true,
                    style: style,
                    align: "left",
                    headerClassName: "super-app-theme--header",
                },
                {
                    field: "free_trial_days",
                    headerName: "Free Trial",
                    flex: 1,
                    minWidth: "110px",
                    sort: true,
                    style: style,
                    align: "left",
                    headerClassName: "super-app-theme--header",
                },
                {
                    field: "description",
                    headerName: "Description",
                    flex: 1,
                    minWidth: "100px",
                    sort: true,
                    style: style,
                    align: "left",
                    headerClassName: "super-app-theme--header",
                },
                {
                    field: "is_enabled",
                    headerName: "Enable/Disable",
                    flex: 1,
                    minWidth: "100px",
                    style: style,
                    sort: true,
                    headerClassName: "super-app-theme--header",
                    renderCell: (params) => (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Switch
                                style={{
                                    backgroundColor: params.row.is_enabled ? "#0F5FCB" : "",
                                }}
                                checked={params.row.is_enabled}
                                onChange={(value) => {
                                    handleEnableDisableChange(params.row.id, value)
                                    console.log(params.row.id);
                                    console.log(value);
                                }}
                                loading={updatingRowId === params.row.id}
                                disabled={updatingRowId === params.row.id}
                            />
                        </div>
                    ),
                },
                {
                    field: "action",
                    headerName: "Action",
                    align: "center",
                    style: style,
                    minWidth: "100px",
                    headerClassName: "super-app-theme--header",
                    renderCell: (params) => (
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <LuEye size="22.5px" style={{
                                color: "#16151C", cursor: "pointer",
                            }}
                                onClick={() => {
                                    setAction("view")
                                    setDataToEdit(params.row)
                                    handleOpenDialog()
                                }}
                            />
                            <CiEdit size="22.5px" style={{
                                color: "#16151C", cursor: "pointer",
                            }}
                                onClick={() => {
                                    setAction("edit")
                                    setDataToEdit(params.row)
                                    handleOpenDialog()
                                }}
                            />
                            <RiDeleteBin5Line size="22.5px" style={{
                                color: "#16151C", cursor: "pointer",
                            }}
                                onClick={() => {
                                    setDeleteConfirmationDialogOpen(true)
                                    setDeletedItemId(params.row.id)
                                }}
                            />
                        </Box>
                    ),
                },
            ] : [
                {
                    field: "user",
                    headerName: "User",
                    flex: 1,
                    minWidth: "150px",
                    sort: true,
                    editable: true,
                    align: "left",
                    style: style,
                    headerClassName: "super-app-theme--header",
                },
                {
                    field: "email",
                    headerName: "Email",
                    flex: 1,
                    minWidth: "100px",
                    sort: true,
                    editable: true,
                    style: style,
                    align: "left",
                    headerClassName: "super-app-theme--header",
                },
                {
                    field: "subscriptionType",
                    headerName: "Subscription Type",
                    flex: 1,
                    minWidth: "180px",
                    style: style,
                    sort: true,
                    align: "left",
                    headerClassName: "super-app-theme--header",
                },
                {
                    field: "startEndDate",
                    headerName: "Start - End Date",
                    flex: 1,
                    style: style,
                    minWidth: "190px",
                    sort: true,
                    align: "center",
                    headerClassName: "super-app-theme--header",
                },
                {
                    field: "renewDate",
                    style: style,
                    headerName: "Renew Date",
                    flex: 1,
                    minWidth: "130px",
                    sort: true,
                    align: "left",
                    headerClassName: "super-app-theme--header",
                },
                {
                    field: "status",
                    headerName: "Status",
                    style: style,
                    flex: 1,
                    minWidth: "50px",
                    sort: true,
                    headerClassName: "super-app-theme--header",
                    renderCell: (params) => (
                        <Status status={params.row.status} />
                    ),
                },
                {
                    style: style,
                    field: "enabled",
                    headerName: "Enable/Disable",
                    flex: 1,
                    minWidth: "50px",
                    sort: true,
                    headerClassName: "super-app-theme--header",
                    renderCell: (params) => (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Switch
                                style={{
                                    backgroundColor: params.row.enabled ? "#0F5FCB" : "",
                                }}
                                checked={params.row.enabled}
                            // Uncomment and handle enable/disable action here
                            // onChange={(value) => handleEnableDisableChange(params.row, value)}
                            />
                        </div>
                    ),
                },
                {
                    field: "action",
                    headerName: "Action",
                    style: style,
                    align: "center",
                    headerClassName: "super-app-theme--header",
                    renderCell: (params) => (
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <LuEye size="22.5px" style={{
                                color: "#16151C", cursor: "pointer", margin: "1px"
                            }} />
                            <CiEdit size="22.5px" style={{
                                color: "#16151C", cursor: "pointer", margin: "1px"
                            }} />
                            <RiDeleteBin5Line size="22.5px" style={{
                                color: "#16151C", cursor: "pointer", margin: "1px"
                            }} />
                        </Box>
                    ),
                },
            ];


    return (
        <Box>
            <AddNewSubscription addNewSubscription={addNewSubscription} onClose={handleCloseDialog} getSubscriptiontypes={getSubscriptiontypes} action={action} dataToEdit={dataToEdit} />

            {/* topside */}
            <Box>
                {/* titel and add btn */}
                <Box

                    xs={12}
                    sm={12}
                    md={9}
                    lg={9}
                    xl={9}
                    style={{
                        display: "flex",
                        gap: "14px",
                        marginTop: "-54px",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <Box style={{
                        display: "flex",
                        flexDirection: 'row',
                        gap: "14px",
                    }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontFamily: "TT Drugs Trial",
                            fontSize: "24px",
                            fontWeight: "700",
                            lineHeight: "33px",
                            marginLeft: "10px"
                        }}>
                            {activeButton === "Subscription Types" ? "All Subscription Types" : "All Subscriptions"}
                        </span>
                    </Box>
                    {activeButton === "Subscription Types" && (
                        <AddButton
                            sx={{
                                marginRight: "10px",
                            }}
                            onClick={() => {
                                setAction("add")
                                handleOpenDialog()
                            }}
                        >
                            +{" "}{"Add New Subscription Type"}
                        </AddButton>
                    )}


                </Box>
            </Box>
            {/* search and filter */}
            <Box

                spacing={1}
                style={{
                    display: "flex",
                    flexDirection: 'row',
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: "10px",
                    marginTop: "20px",
                    backgroundColor: "white",
                    height: "70px",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px"
                }}>

                <Box

                    spacing={1}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: "10px",
                        paddingTop: "18px",
                    }}>
                    <Box
                        style={{
                            border: `1.5px solid ${colorConfigs?.sidebar?.active}`,
                            borderRadius: "8px",
                            marginTop: "2px",
                            marginBottom: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "7px",
                        }}
                        sx={{
                        }}>
                        <Box >
                            <Button
                                variant={activeButton === "Subscription Types" ? "contained" : "text"}
                                style={{
                                    color:
                                        activeButton === "Subscription Types"
                                            ? "white"
                                            : colorConfigs?.sidebar?.active,
                                    backgroundColor:
                                        activeButton === "Subscription Types"
                                            ? colorConfigs?.sidebar?.active
                                            : "transparent",
                                    fontWeight: "600",
                                    fontSize: activeButton === "Subscription Types" ? "16px" : "15px",
                                    textTransform: 'capitalize'
                                }}
                                onClick={() => setActiveButton("Subscription Types")}>
                                Subscription Types
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                variant={activeButton === "Subscription" ? "contained" : "text"}
                                style={{
                                    color:
                                        activeButton === "Subscriptions"
                                            ? "white"
                                            : colorConfigs?.sidebar?.active,
                                    backgroundColor:
                                        activeButton === "Subscriptions"
                                            ? colorConfigs?.sidebar?.active
                                            : "transparent",
                                    fontWeight: "bold",
                                    fontSize: activeButton === "Subscriptionss" ? "16px" : "15px",
                                    whiteSpace: "normal",
                                    textTransform: 'capitalize'
                                }}
                                onClick={() => setActiveButton("Subscriptions")}>
                                Subscriptions
                            </Button>
                        </Box>
                    </Box>
                </Box>

                <Box style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    {/* <InputField
                        id="Search"
                        placeholder="Search"
                        type="text"
                        value={currentKeyword}
                        startAdornment={<FiSearch size={24} color="#88939D" />}
                        variant="outlined"
                        sx={{
                            minWidth: "350px",
                            fontWeight: "500",
                            fontSize: "18px",
                        }}
                    /> */}
                    {/* <Box>    Filter</Box> */}
                </Box>

            </Box>
            <DynamicTable
                columns={columns}
                data={data}
                count={data?.length}
                page={page}
                rowsPerPage={rowsPerPage}
                pageChange={handlePageChange}
                rowsChange={handleRowsChange}
                pagetype={activeButton === 'Subscription Types' ? "subscriptionTypes" : "subscriptions"}
            />
            <Dialog
                style={{ zIndex: 10, borderRadius: "30px" }}
                open={deleteConfirmationDialogOpen}
                onClose={() => setDeleteConfirmationDialogOpen(false)}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent style={{ padding: "30px" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={deletee} alt="" />
                    </div>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <Typography
                            variant="h6"
                            style={{
                                fontWeight: "bold",
                                color: "black",
                                marginTop: "10px",
                                marginBottom: "8px",
                            }}
                        >
                            Delete Subscription Type
                        </Typography>
                    </DialogContentText>
                    <Typography>
                        Are you sure you want to delete this Type?
                    </Typography>
                </DialogContent>

                <DialogActions
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginBottom: "20px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}
                >
                    <Button
                        variant="outlined"
                        style={{
                            color: "black",
                            borderColor: "lightgray",
                            width: "100%",
                        }}
                        onClick={() => setDeleteConfirmationDialogOpen(false)}
                        disabled={loading}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        style={{
                            background: "var(--Error, #FD4343)",
                            color: "white",
                            width: "100%",
                        }}
                        onClick={() => handleDeleteConfirmation()}
                        disabled={loading}
                    >
                        {loading ? "Deleting..." : "Yes, Delete"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>

    );
}

export default Subscription;
