import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const isAuth = localStorage.getItem("isAuth");
  const location = useLocation();

  // console.log(location.pathname);
  // console.log(isAuth);


  if (!isAuth) {
    if (location.pathname !== "/login") {
      return <Navigate to="/login" />;
    }
  } else {
    if (location.pathname === "/login" || location.pathname === "/createNewPassword") {
      return <Navigate to="/users" />;
    }
  }

  return children;

};

export default ProtectedRoute;
