import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0F5FCB",
      darkMain: "#0D52B2"
    },
    secondary:{
      main: "#172B4D"
    },
    text: {
      lightBlack: "#16151C",
      grayLavender: "#A2A1A8",
      coolGray: "#88939D",
      lavenderGray: "#BBC1CE",
      steelBlue: "#7A869A"
    },
    status: {
        error: "#F04438",
    },
    basic: {
        white: "#fff",
        black: "#000"
    },
    hover:{
      lightLavenderGray: "#E6E9F2"
    }, 
    shadow:{
      blackOpacity: "rgba(0, 0, 0, 0.1)"
    }
  },
  typography: {
    fontFamily: "TT Drugs Trial",
    textTransform: "capitalize",
  },
});

export default theme;
