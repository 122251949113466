import React, { useEffect, useRef } from 'react';
import { Box, Button, Card, CardContent, Typography, Grid } from '@mui/material';
import colorConfigs from '../../configs/colorConfigs';
import CardIcon from "../../assets/svg/CardIcon";
import InputField from "../../components/InputField"
import { FiSearch } from "react-icons/fi";
import DynamicTable from "../../pages/table/table";
import { useState } from "react";
import { Switch } from "antd";
import { LuEye } from "react-icons/lu";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin5Line } from "react-icons/ri";
import DynamicFilter from "../filter/DynamicFilter"
import AddButton from '../../components/common/Buttons/AddButton';
import axios from "axios";
import { API_URL } from "../../utils/constants";
import theme from '../../configs/theme';

function Users() {
    
    const items = [
        { title: 'All Users', value: 250, icon: <CardIcon /> },
        { title: 'Active Users', value: 150, icon: <CardIcon /> },
        { title: 'Disabled Accounts', value: 100, icon: <CardIcon /> },
        { title: 'Subscription Distribution', value: 35 }
    ];
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState({});
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const currentPage = useRef(1);
    const currentLimit = useRef(10);
    const currentKeyword = useRef("");
    const currentStatus = useRef("all");
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        currentPage.current = newPage;
    };

    const handleRowsChange = (rows) => {
        currentPage.current = 1;
        currentLimit.current = rows;
        setPage(1);
        setRowsPerPage(rows);
    };

    const data = [
        {
            id: 1,
            name: "John Doe",
            email: "ex1",
            profile: 0,
            cardLink: "link",
            subscriptionType: "Yearly",
            enabled: true,
        },
        {
            id: 2,
            name: "Jane Doe",
            email: "ex2",
            profile: 0,
            cardLink: "link",
            subscriptionType: "Monthly",
            enabled: false,
        },
        {
            id: 3,
            name: "Mike Doe",
            email: "ex3",
            profile: 0,
            cardLink: "link",
            subscriptionType: "Yearly",
            enabled: true,
        },
        {
            id: 4,
            name: "Sarah Doe",
            email: "ex4",
            profile: 0,
            cardLink: "link",
            subscriptionType: "Monthly",
            enabled: false,
        }
    ]

    const columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            sort: true,
            minWidth: "100px",
            editable: true,
            style: styles?.actionCoulmn,
            align: "left",
            headerClassName: "super-app-theme--header",
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
            sort: true,
            minWidth: "50px",
            editable: true,
            style: styles?.actionCoulmn,
            align: "left",
            headerClassName: "super-app-theme--header",
        },
        {
            field: "profile",
            headerName: "Profiles",
            minWidth: "50px",
            flex: 1,
            sort: true,
            style: styles?.actionCoulmn,
            align: "left",
            headerClassName: "super-app-theme--header",
        },
        {
            field: "cardLink",
            headerName: "Card Link",
            minWidth: "100px",
            flex: 1,
            sort: true,
            style: styles?.actionCoulmn,
            align: "left",
            headerClassName: "super-app-theme--header",
        },
        {
            field: "subscriptionType",
            headerName: "Subscription Type",
            minWidth: "50px",
            flex: 1,
            sort: true,
            style: styles?.actionCoulmn,
            align: "left",
            headerClassName: "super-app-theme--header",
        },
        {
            field: "enabled",
            sort: true,
            headerName: "Enable/Disable",
            headerClassName: "super-app-theme--header",
            minWidth: "50px",
            renderCell: (params) => (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Switch
                        style={{
                            backgroundColor: params.row.enabled ? "#0F5FCB" : "",
                        }}
                        checked={params.row.enabled}
                    //   onChange={(value) => pagesRoles?.genral?.tableEnable_And_disable ? handleEnableDisableChange(params.row, value) : ''
                    //   }
                    />
                </div>
            ),
        },
        {
            field: "action",
            headerName: "Action",
            align: "center",
            style: styles?.actionCoulmn,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                // onClick={() => { navigate(`/drivers/details/${params.row._id}`); dispatch(setSelectedUser(params.row)); }}
                >
                    <LuEye size="22.5px" style={{
                        color: "#16151C", cursor: "pointer",
                    }} />
                    <CiEdit size="22.5px" style={{
                        color: "#16151C", cursor: "pointer",
                    }} />
                    <RiDeleteBin5Line size="22.5px" style={{
                        color: "#16151C", cursor: "pointer",
                    }} />
                </Box>
            ),
        },

    ];

    const filterData = {

        'Subscription Type': [
            {
                name: 'subscriptionType',
                gridWidth: { xs: 12, sm: 12 },
                list: [
                    { label: 'Monthly Plan', value: 'monthly' },
                    { label: 'Yearly Plan', value: 'yearly' },
                ],
                type: 'checkbox', // Displays as a horizontal checkbox
                direction: 'horizontal',
                placeholder: 'Do you accept the terms and conditions?',
            },
        ],
        'Profile Status': [
            {
                name: 'profileStatus',
                gridWidth: { xs: 12, sm: 12 },
                list: [
                    { label: 'Enabled', value: 'enabled' },
                    { label: 'Disabled', value: 'eisabled' },
                ],
                type: 'checkbox', // Displays as a horizontal checkbox
                direction: 'horizontal',
                placeholder: 'Do you accept the terms and conditions?',
            },
        ],
        'Date of Registration': [
            {
                name: 'dateOfRegistration',
                gridWidth: { xs: 12, sm: 12 },
                type: 'date',
                placeholder: 'Select Date',
            },
        ],
        'Number of Profiles': [
            {
                name: 'numberOfProfiles',
                gridWidth: { xs: 12 },
                type: 'text',
                output: 'number',
                placeholder: 'Enter Number Of Profiles',
            },
        ],

    };

    const getUsers = async (tagname) => {
        setLoading(true);
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${API_URL}/user?page=0&limit=${rowsPerPage}`,
            headers: {
                "Content-Type": "application/json",
            },
        };
        axios
            .request(config)
            .then((response) => {
                const parsedData = JSON.parse(response?.data);
                const users = parsedData?.data?.data;
                console.log(users);

                setUsers(users);
                setLoading(false);
            })
            .catch((error) => {
                console.log("Error Get card: ", error);
                setLoading(false);
            });
    }
    useEffect(() => {
        getUsers()
    }, [rowsPerPage])

    return (
        <Box>
            {/* topside */}
            <Box>
                {/* titel and add btn */}
                <Box

                    xs={12}
                    sm={12}
                    md={9}
                    lg={9}
                    xl={9}
                    style={{
                        display: "flex",
                        gap: "14px",
                        marginTop: "-130px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        marginLeft: "6px",
                    }}>
                    <Box style={{
                        display: "flex",
                        flexDirection: 'row',
                        gap: "14px",
                    }}>
                        <Typography
                        variant='h5' 
                        style={{
                            color: theme.palette.basic.white,
                            fontWeight: "700",
                            lineHeight: "2.1rem",
                            marginLeft: "10px"
                        }}>
                            All Users
                        </Typography>
                    </Box>
                    <AddButton
                    // onClick={() => {}}
                    >
                        +{" "}{"Add New User"}
                    </AddButton>

                </Box>
            </Box>
            {/* cards */}
            <Box

                spacing={2}
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: "8px",
                    gap: 2,
                    p: 1
                }}
            >
                {items.map((item, index) => (
                    <Box key={index} xs={12} sm={6} md={3} lg={3} sx={{ width: { xs: '100%', sm: '48%', md: '23%' } }}>
                        <Card
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding:"20px 8px",
                                boxShadow: `${theme.palette.shadow.blackOpacity} 0px 4px 4px`,
                                borderRadius: "8px",
                                gap: 2,
                            }}
                        >
                            <CardContent
                                sx={{
                                    width: item.icon ? '70%' : '100%',
                                    textAlign: 'left',
                                    p: 0
                                }}
                            >
                                <Typography variant='body2' sx={{ color: theme.palette.secondary.main, fontWeight: 800, lineHeight: "1.125rem" }} component="div">
                                    {item.title}
                                </Typography>
                                <Typography variant='h5' sx={{ color: theme.palette.primary.main, fontWeight: 800, lineHeight: "30.24px", mt: 1, ml: .5 }} component="div">
                                    {item.value}
                                </Typography>
                            </CardContent>

                            {item.icon ? (
                                <Box
                                // sx={{ ...styles.hoverEffectStyle }}
                                >
                                    {item.icon}
                                </Box>
                            ) : (
                                <Box>
                                </Box>
                            )}
                        </Card>
                    </Box>
                ))}
            </Box>
            {/* search and filter */}
            <Grid container
                spacing={1}
                style={{
                    paddingLeft: "10px",
                    marginTop: "10px",
                    marginLeft:"0",
                    backgroundColor: theme.palette.basic.white,
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px"

                }}>
                <Grid item xs={12} md={3} display={"flex"} alignItems={"center"}
                >
                    <Typography variant='h6' 
                    style={{
                        color: theme.palette.secondary.main,
                        fontWeight: "700",
                    }}> All Users
                    </Typography>
                </Grid>
                <Grid item xs={12} md={9} display={"flex"} gap={"9px"} alignItems={"end"} justifyContent={"end"} style={{padding:"15px 10px 15px 0"}}>

                <Grid item xs={12} md={5}  >
                <InputField
                        id="Search"
                        placeholder="Search"
                        type="text"
                        // value={currentKeyword}
                        startAdornment={<FiSearch size={24} color="#88939D" />}
                        variant="outlined"
                        sx={{
                            fontWeight: "500",
                            fontSize: "18px",
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={3}  style={{maxWidth: "fit-content"}}>
                <DynamicFilter
                            onApply={(data) => console.log(data)}
                            filterData={filterData}
                            mainColor={colorConfigs.sidebar.active}
                            inputsStyle={{ borderRadius: '10px', height: '45px', fontSize: '16px' }}
                        />
                </Grid>
                </Grid>
            </Grid>
            <DynamicTable
                columns={columns}
                data={data}
                count={data?.length}
                page={page}
                rowsPerPage={rowsPerPage}
                pageChange={handlePageChange}
                rowsChange={handleRowsChange}
                pagetype="users"
            />
        </Box>

    );
}


const styles= {
    actionCoulmn:{
        color: "#172B4D",
        fontSize: "14.5px",
        fontWeight: "600",
        lineHeight: "20px",
    },
    hoverEffectStyle : {
        cursor: "pointer",
        borderRadius: 25,
        height: '60px',
        width: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
            transform: 'scale(1.2)',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
        }
    }
}
export default Users;
