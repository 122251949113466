import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { setOpenDrawer } from "../../redux/features/appStateSlice";
const Topbar = () => {
  const dispatch = useDispatch();
  const openDrawer = useSelector(state => state.appState.openDrawer);
  const [isOpenDrawer, setIsOpenDrawer] = useState(openDrawer);
  const location = useLocation();
  const params = useParams();
  useEffect(() => {
    setIsOpenDrawer(openDrawer);
  }, [openDrawer]);
  const path = location.pathname.split("/").filter(segment => segment !== "");

  const getPath = () => {
    if (location.pathname.includes('driversRequests')) {
      return '/drivers';
    }
    return `/${path[0]}`;
  };

  const formattedPath = location.pathname.replace(/\//g, ' / ');

  return (
    <div style={{
      marginTop: 60,
      display: 'flex',
      marginRight: '1%',
      marginBottom: '50px',
      position: 'relative',
      zIndex: 5,
      marginLeft: !isOpenDrawer ? '70px' : '19%',
      width: `calc(100% - ${isOpenDrawer ? '19%' : '70px'})`,
      justifyContent: 'space-between',
      alignItems: 'start'
    }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'start',
          gap: '10px',
        }}
      >
        <div>
          <DensityMediumIcon
            onClick={() => dispatch(setOpenDrawer(!isOpenDrawer))}
            style={{
              color: 'white',
              marginRight: '1%',
              cursor: 'pointer',
              display: !openDrawer ? 'block' : 'none'
            }}
          />
        </div>
        <Typography
          style={{
            marginLeft: '5px',
            fontWeight: 600,
            color: '#BBC1CE',
            fontSize: '15px',
            lineHeight: '24px',
            textTransform: 'capitalize',
            minWidth: '50px',
            display: 'inline-block',
          }}
        >
          Dashboard{" "}

          <Link to={getPath()} style={{ textDecoration: 'none', color: 'inherit' }}>
            {formattedPath}

          </Link>
        </Typography>
      </div>

    </div>
  );
};
export default Topbar;