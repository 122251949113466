import React from "react";
import { MdOutlineSubscriptions } from "react-icons/md";
import { AiFillStar } from "react-icons/ai";
import { Box } from "@mui/material";

function CustomSubscriptionIcon({ size = "25px", active }) {
    return (
        <Box position="relative" display="inline-block" width={size} height={size}>
            <MdOutlineSubscriptions style={{ fontSize: size, color: active ? "" : "white" }} />
            <AiFillStar
                style={{
                    position: "absolute",
                    top: "68%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "11px",
                    color: active ? "" : "white",
                }}
            />
        </Box>
    );
}

export default CustomSubscriptionIcon;