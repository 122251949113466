import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill="#A7A8AB"
            d="M14 6.99V14h2V6.99h3L15 3l-4 3.99h3ZM13 17.01h-3V10H8v7.01H5L9 21l4-3.99Z"
        />
    </svg>
)
const SortArrows = memo(SvgComponent)
export default SortArrows
