import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: "#05172E",
    active: "#0F5FCB",
    color: "#eeeeee",
    hoverBg: "white",
    activeBg: "#0075b0",
    meduimColor: '#C0EAFF',
    ligthBg: "#f5fcff"
  },
  topbar: {
    bg: "#fff",
    color: "#000",
  },
  mainBg: colors.grey["100"],
  mainColor: "#000000",
  lightColor: "#EEEEEE7A",
  grayText: "#727272",
  button: "#0075b0",
};

export default colorConfigs;