import { Button, styled } from '@mui/material';
import colorConfigs from '../../../configs/colorConfigs';
import theme from '../../../configs/theme';

const AddButton = styled(Button)(() => ({
    backgroundColor: theme.palette.basic.white,
    borderRadius: "20px",
    fontWeight: "700",
    fontSize: "16.5px",
    lineHeight: "18px",
    color: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.hover.lightLavenderGray,
    },
    marginRight: "20px",
    padding: "10px 30px",
    textTransform: "none",
}));

export default AddButton