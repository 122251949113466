import React from "react";
import { Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Status = ({ status }) => {



  return status === "open" ? (
    <div style={styles.containerOpen}>
      <Typography style={styles.colorOpen}>Open</Typography>
    </div>
  ) : status === "paid" || status === "active" ? (

    <div style={styles.containerPaid}>
      <Typography style={styles.colorPaid}> {status === "paid" ? "Paid" : "Active"}</Typography>
    </div>
  ) : status === "unpaid" || status === "inactive" ? (
    <div style={styles.containerUnpaid}>
      <Typography display="flex" alignItems="center" style={styles.colorUnpaid}> {status === "unpaid" ? "Unpaid" : "Inactive"}  </Typography>
    </div>
  ) : status === "assigned" ? (
    <div style={styles.containerAssigned}>
      <Typography style={styles.colorAssigned}>Assigned</Typography>
    </div>
  ) : status === "completed" ? (
    <div style={styles.containerCompleted}>
      <Typography style={styles.colorCompleted}>Completed</Typography>
    </div>
  ) : status === "new" ? (
    <div style={styles.containerNew}>
      <Typography display="flex" alignItems="center" style={styles.colorNew}>New </Typography>
    </div>
  ) : status === "onProgress" || status === "inProgress" ? (
    <div style={styles.containerOnProgress}>
      {status === "onProgress" ? (<Typography style={styles.colorOnProgress}>On Progress</Typography>) :
        (
          <Typography display="flex" alignItems="center" style={styles.colorOnProgress}>InProgress </Typography>
        )}
    </div>
  ) : status === "onJob" ? (
    <div style={styles.containerErrors}>
      <Typography style={styles.colorErrors}>On Job</Typography>
    </div>
  ) : status === "outcoming" ? (
    <div style={styles.containerOnProgress}>
      <Typography style={styles.colorOnProgress}>Outcoming</Typography>
    </div>
  ) : status === "incoming" ? (
    <div style={styles.containerErrors}>
      <Typography style={styles.colorErrors}>Incoming</Typography>
    </div>
  ) : status === "scheduled" ? (
    <div style={styles.containerScheduled}>
      <Typography style={styles.colorScheduled}>Scheduled</Typography>
    </div>
  ) : status === "submitted" ? (
    <div style={styles.containerSubmitted}>
      <Typography style={styles.colorSubmitted}>Submitted</Typography>
    </div>
  ) : status === "pickedup" ? (
    <div style={styles.containerSubmitted}>
      <Typography style={styles.colorSubmitted}>Picked up</Typography>
    </div>
  ) : status === "underreview" ? (
    <div style={styles.containerUnderReview}>
      <Typography style={styles.colorUnderReview}>Under Review</Typography>
    </div>
  ) : status === "delivered" ? (
    <div style={styles.containerActive}>
      <Typography style={styles.colorActive}>Delivered</Typography>
    </div>
  ) : status === "approved" ? (
    <div style={styles.containerActive}>
      <Typography style={styles.colorActive}>Approved</Typography>
    </div>
  ) : status === "canceled" ? (
    <div style={styles.containerErrors}>
      <Typography style={styles.colorErrors}>Cancelled</Typography>
    </div>
  ) : status === "busy" ? (
    <div style={styles.containerErrors}>
      <Typography style={styles.colorErrors}>Busy</Typography>
    </div>
  ) : status === "enroute_to_customer" ? (
    <div style={styles.containerAssigned}>
      <Typography style={styles.colorAssigned}>On the way...</Typography>
    </div>
  ) : status === "offline" ? (
    <div style={styles.containerOffline}>
      <Typography style={styles.colorOffline}>Offline</Typography>
    </div>
  ) : status === "pending" ? (
    <div style={styles.containerPending}>
      <Typography style={styles.colorPending}>Pending</Typography>
    </div>
  ) : status === "incomplete" ? (
    <div style={styles.containerPending}>
      <Typography style={styles.colorPending}>Incomplete</Typography>
    </div>
  ) : status === "awaiting_pickup" ? (
    <div style={styles.containerPending}>
      <Typography style={styles.colorPending}>Waiting</Typography>
    </div>
  ) : status === "waiting_delivery" ? (
    <div style={styles.containerPending}>
      <Typography style={styles.colorPending}>Waiting</Typography>
    </div>
  ) : status === "reject" ? (
    <div style={styles.containerRejected}>
      <Typography style={styles.colorRejected}>Rejected</Typography>
    </div>
  ) : status === "blocked" ? (
    <div style={styles.containerRejected}>
      <Typography display="flex" alignItems="center" style={styles.colorRejected}>Blocked <ExpandMoreIcon style={{ color: "#D50000", fontSize: "18px" }} /> </Typography>
    </div>
  ) :




    (
      ""
    );
};
const styles = {


  colorUnpaid: {
    color: "#D50000",
    fontSize: "14px",
    fontWeight: "600",
  },
  containerUnpaid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 16px",
    borderRadius: "25px",
    backgroundColor: "#FFF5F5",
  },

  containerPaid: {
    borderRadius: "25px",
    backgroundColor: "#E8FAE7",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  colorPaid: {
    color: "#01973D",
    fontSize: "14px",
    fontWeight: "600",
  },
  colorOffline: {
    color: "#495974",
    fontSize: "12px",
    fontWeight: "600",
    fontFamily: "Poppins",
  },
  containerOffline: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 16px",
    borderRadius: "25px",
    backgroundColor: "#EEEEEE",
  },
  colorPending: {
    color: "#E77D00",
    fontSize: "12px",
    fontWeight: "600",
    fontFamily: "Poppins",
  },
  containerPending: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 16px",
    borderRadius: "25px",
    backgroundColor: "#FFF1EA",
  },
  colorRejected: {
    color: "#D50000",
    fontSize: "12px",
    fontWeight: "600",
  },
  containerRejected: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 16px",
    borderRadius: "25px",
    backgroundColor: "#FFF5F5",
  },
  colorOpen: {
    color: "#2F5EBA",
    fontSize: "12px",
    fontWeight: "600",
    marginTop: 3,
  },
  colorCompleted: {
    color: "#32936F",
    fontSize: "12px",
    marginTop: 3,
  },
  colorOnJob: {
    color: "rgba(235, 80, 60, 0.9)",
    fontSize: "12px",
    fontWeight: "600",
  },
  containerOpen: {
    justifyContent: "center",
    marginTop: 5,
    height: "25px",
    borderRadius: "25px",
    backgroundColor: "#5388EF29",
    display: "flex",
    padding: "4px 16px",
    alignItems: "center",
  },
  containerCompleted: {
    height: "25px",
    display: "flex",
    justifyContent: "center",
    marginTop: 5,
  },
  // ------
  containerNew: {
    backgroundColor: "#F0FAFF",
    justifyContent: "center",
    marginTop: 5,
    height: "25px",
    padding: "4px 16px",
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
  },
  colorNew: {
    color: "#206CFF",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "18px",
  },
  containerResolved: {
    justifyContent: "center",
    marginTop: 5,
    height: "25px",
    padding: "4px 16px",
    borderRadius: "25px",
    backgroundColor: "#E8FAE7",
    display: "flex",
    alignItems: "center",
  },
  colorResolved: {
    color: "#01973D",
    fontSize: "14.5px",
    fontWeight: 600,
    lineHeight: "18px",

  },
  containerActive: {
    padding: "4px 16px",
    borderRadius: "25px",
    backgroundColor: "rgba(50, 147, 111, 0.16)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  colorActive: {
    color: "#01973D",
    fontSize: "12px",
    fontWeight: "600",
  },
  containerAssigned: {
    backgroundColor: "rgba(50, 147, 111, 0.16)",
    padding: "4px 16px",
    borderRadius: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  colorAssigned: {
    color: "#32936F",
    fontSize: "12px",
    fontWeight: "600",
  },
  containerOnProgress: {

    backgroundColor: "#FAF6E7",
    justifyContent: "center",
    marginTop: 5,
    // height: "25px",
    padding: "4px 16px",
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
  },
  colorOnProgress: {
    color: "#EE8F02",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "18px",
  },
  containerOnWork: {
    height: "25px",
    borderRadius: "25px",
    backgroundColor: "rgba(255, 165, 0, 0.16)",
    display: "flex",
    justifyContent: "center",
    marginTop: 5,
  },
  colorOnWork: {
    color: "#EE8F02",
    fontSize: "12px",
    marginTop: 3,
  },
  containerScheduled: {
    borderRadius: "25px",
    backgroundColor: "rgba(33, 150, 243, 0.16)",
    padding: "4px 16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  colorScheduled: {
    color: "#00AAFF",
    fontSize: "12px",
    fontWeight: 600,
  },
  containerSubmitted: {

    backgroundColor: "rgba(138, 43, 226, 0.16)",
    padding: "4px 16px",
    borderRadius: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  colorSubmitted: {

    color: "#5E0FA6D9",
    fontSize: "12px",
    fontWeight: "600",

  },
  containerUnderReview: {
    height: "25px",
    borderRadius: "25px",
    backgroundColor: "rgba(255, 193, 7, 0.16)",
    display: "flex",
    justifyContent: "center",
    marginTop: 5,
  },
  colorUnderReview: {
    color: "#9D7A10D9",
    fontSize: "12px",
    marginTop: 3,
  },
  containerErrors: {
    padding: "4px 16px",
    borderRadius: "25px",
    backgroundColor: "#F9E0E0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  colorErrors: {
    color: "#D50000",
    fontSize: "12px",
    fontWeight: "600",
  },
  containerCanceled: {
    height: "25px",
    borderRadius: "25px",
    backgroundColor: "rgba(244, 67, 54, 0.16)",
    display: "flex",
    justifyContent: "center",
    marginTop: 5,
  },
  colorCanceled: {
    color: "#B92015D9",
    fontSize: "12px",
    marginTop: 3,
  },
};
export default Status;
