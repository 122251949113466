import { Button, styled } from '@mui/material';
import theme from '../../../configs/theme';

const BlueButton = styled(Button)(() => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.basic.white,
    borderRadius: '10px',
    height: '48px',
    width: '140px',
    fontWeight: '600',
    fontSize: "15px",
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: theme.palette.primary.darkMain,
        color: theme.palette.basic.white,
    },
}));

export default BlueButton